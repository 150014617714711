import { useMediaQuery } from '@kaliber/use-media-query'
import { useUrls } from '/domain/useUrls'
import { useKeenSlider } from 'keen-slider/react'
import { Icon } from '../buildingBlocks/Icon'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import { useContrastingStyle } from '/machinery/StyleContext'
import { useTranslate } from '/machinery/I18n'
import { JobListingCardRelated, JobListingCardRelatedHoliday } from '/features/buildingBlocks/JobListing'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { routeMap } from '/routeMap'
import { useLocationMatch } from '@kaliber/routing'
import { appendQueryString } from '/machinery/appendQueryString'

import mediaStyles from '/cssGlobal/media.css'
import styles from './RelatedJobs.css'

import iconChevronRight from '/images/icons/chevron-right.raw.svg'
import iconChevronLeft from '/images/icons/chevron-left.raw.svg'

export function RelatedJobsFlexible({ jobs, title, label, filters, layoutClassName = undefined }) {
  return (
    <RelatedJobsBase {...{ jobs, title, layoutClassName }}>
      <Button params={filters} {...{ label }}/>
    </RelatedJobsBase>
  )
}

export function RelatedJobsFlexibleHoliday({ jobs, title, label, filters, layoutClassName = undefined }) {
  return (
    <RelatedJobsBase {...{ jobs, title, layoutClassName }} CardComponent={JobListingCardRelatedHoliday}>
      <Button params={filters} {...{ label }}/>
    </RelatedJobsBase>
  )
}

export function RelatedJobs({ jobs, title, layoutClassName = undefined }) {
  return (
    <RelatedJobsBase {...{ jobs, title, layoutClassName }}>
      <ShopButton />
      <OfficeButton />
    </RelatedJobsBase>
  )
}

export function RelatedJobsOffice({ jobs, title, layoutClassName = undefined }) {
  return (
    <RelatedJobsBase {...{ jobs, title, layoutClassName }}>
      <OfficeButton />
    </RelatedJobsBase>
  )
}

export function RelatedJobsShop({ jobs, title, layoutClassName = undefined }) {
  return (
    <RelatedJobsBase {...{ jobs, title, layoutClassName }} >
      <ShopButton />
    </RelatedJobsBase>
  )
}

function RelatedJobsBase({ jobs, title, children, CardComponent = JobListingCardRelated, layoutClassName = undefined }) {
  const contrastingStyle = useContrastingStyle()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const { __ } = useTranslate()

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    slides: {
      perView: isViewportLg ? 3.2 : isViewportMd ? 2.2 : 1.2,
      spacing: isViewportMd ? 30 : 15,
      origin: isViewportMd ? 'auto' : 'center',
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    dragChecked(slider) {
      trackInteraction({
        title: title ?? 'related-jobs',
        action: 'vertical scrolled',
        type: 'slided',
        index: slider.track.details.rel
      })
    },
    selector: x => x.childNodes,
  })

  React.useEffect(
    () => instanceRef.current.update(),
    [instanceRef, isViewportMd]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <HeadingXl h='2' layoutClassName={styles.headingLayout} {...{ title }} />

      <div className={styles.sliderAndButtons}>
        <div className={styles.button}>
          <IconButton
            icon={iconChevronLeft}
            ariaLabel={__`show-previous-slide`}
            dataX='goto-previous-slide'
            onClick={() => instanceRef.current?.prev()}
            layoutClassName={cx(styles.iconLayout, styles.chevronLeftLayout, (currentSlide === 0) && styles.hide)}
          />
        </div>

        <div className={styles.sliderContainer} data-style-context={contrastingStyle}>
          <div className={styles.slider} ref={sliderRef}>
            {jobs.map(({ _source: job }, i) =>
              <CardComponent key={i} {...{ job }} layoutClassName={styles.cardLayout} />
            )}
          </div>
        </div>

        <div className={styles.button}>
          <IconButton
            icon={iconChevronRight}
            ariaLabel={__`show-next-slide`}
            dataX='goto-next-slide'
            onClick={() => instanceRef.current?.next()}
            layoutClassName={cx(styles.iconLayout, styles.chevronRightLayout,  (currentSlide === jobs.length - 3) && styles.hide)}
          />
        </div>
      </div>

      <div className={styles.buttons}>
        {children}
      </div>
    </div>
  )
}

function Button({ params, label }) {
  const { params: { language } } = useLocationMatch()
  const jobsOverview = routeMap.app.jobs.overview({ language })

  return (
    <ButtonGhost
      dataX='link-to-jobsOverview'
      href={appendQueryString(jobsOverview, params)}
      layoutClassName={styles.buttonLayout}
    >
      {label}
    </ButtonGhost>
  )
}

function ShopButton() {
  const urls = useUrls()
  const { __ } = useTranslate()

  return (
    <ButtonGhost
      dataX='link-to-jobsOverview'
      href={urls.jobOverview.shops()}
      layoutClassName={styles.buttonLayout}
    >
      {__`explore-all-shop-jobs`}
    </ButtonGhost>
  )
}

function OfficeButton() {
  const urls = useUrls()
  const { __ } = useTranslate()

  return (
    <ButtonGhost
      dataX='link-to-jobsOverview'
      href={urls.jobOverview.offices()}
      layoutClassName={styles.buttonLayout}
    >
      {__`explore-all-office-jobs`}
    </ButtonGhost>
  )
}

function IconButton({ icon, dataX, ariaLabel, onClick, layoutClassName }) {
  return (
    <button
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(styles.componentIconButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon {...{ icon }} />
    </button>
  )
}
